<template>
  <div class="yandex-maps-restaurants v-position-relative">
    <div
      :id="uid"
      class="v-restaurant-select-map-container"
    />
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="selectedRestaurant"
        class="v-position-absolute v-map-popup"
      >
        <div class="v-map-popup--content">
          <div
            class="v-restaurant-popup v-map-popup--left-part v-d-flex v-flex-column"
          >
            <div v-if="!stringIsNullOrWhitespace(selectedRestaurant.Address)">
              <page-restaurant-address :hall="selectedRestaurant" />
            </div>
            <div class="v-restaurant-popup--hr v-py-sm">
              <hr />
            </div>
            <page-restaurant-info :hall="selectedRestaurant" />
          </div>
          <div class="v-map-popup--close">
            <arora-button
              class-name="v-btn v-btn-square v-square-small v-btn-text"
              ignore-settings
              :label="translate('restaurantsPage.closeButton')"
              @click="clear"
            >
              <icon-old-general-cross />
            </arora-button>
          </div>
        </div>

        <page-restaurant-buttons
          show-details
          :hall="selectedRestaurant"
          :reservation-callback="reservationCallback"
        />
      </div>
    </transition>
  </div>
  <template v-if="loaded && pageStore.RestaurantsForMap.length > 0">
    <Teleport
      v-for="restaurant in pageStore.RestaurantsForMap"
      :key="`#restaurant-${sanitize(restaurant.ID)}`"
      :to="`#restaurant-${restaurant.ID}`"
    >
      <icon-old-address-mark class="v-restaurant-icon" />
    </Teleport>
  </template>
</template>

<script setup lang="ts">
import type { YMapMarker } from '@yandex/ymaps3-types'
import type { YMapBounds } from '~types/mapsStore'
import type { Hall } from '~types/pageStore'

import { useCommon } from '@arora/common'

defineProps<{
  reservationCallback: () => void
}>()
const loaded = ref<boolean>(false)
const selectedRestaurant = ref<Hall | null>(null)

const mapsStore = useMapsStore()
const pageStore = usePageStore()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

onMounted(async () => {
  await (mapsStore.YandexMaps.state === 'success'
    ? makeMap()
    : mapsStore.initYandexMaps())
})

const ymaps3Loaded = computed<boolean>(
  () =>
    mapsStore.YandexMaps.state === 'success' && pageStore.Restaurants?.data !== null
)

watch(ymaps3Loaded, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await makeMap()
  }
})

const bounds: YMapBounds = {
  maxLatitude: 0,
  maxLongitude: 0,
  minLatitude: 9999,
  minLongitude: 9999
}

let lock = false

async function makeMap(): Promise<void> {
  if (lock) return
  lock = true
  const placemarks: YMapMarker[] = []

  for (const restaurant of pageStore.RestaurantsForMap) {
    if (restaurant.Latitude && restaurant.Longitude) {
      if (restaurant.Latitude > bounds.maxLatitude)
        bounds.maxLatitude = restaurant.Latitude
      if (restaurant.Longitude > bounds.maxLongitude)
        bounds.maxLongitude = restaurant.Longitude

      if (restaurant.Latitude < bounds.minLatitude)
        bounds.minLatitude = restaurant.Latitude
      if (restaurant.Longitude < bounds.minLongitude)
        bounds.minLongitude = restaurant.Longitude

      const markerElement = document.createElement('div')
      markerElement.id = `restaurant-${restaurant.ID}`
      markerElement.addEventListener('click', () => {
        mapsStore.changeYMapBounds(uid, {
          maxLatitude: restaurant.Latitude! + mapsStore.tilt / 100 + 0.00015,
          maxLongitude: restaurant.Longitude! + mapsStore.tilt / 100 + 0.00015,
          minLatitude: restaurant.Latitude! + mapsStore.tilt / 100 - 0.00015,
          minLongitude: restaurant.Longitude! + mapsStore.tilt / 100 - 0.00015
        })
        selectedRestaurant.value = restaurant
      })

      const marker = new ymaps3.YMapMarker(
        {
          coordinates: [restaurant.Longitude, restaurant.Latitude],
          id: restaurant.ID,
          onClick: () => {
            mapsStore.changeYMapBounds(uid, {
              maxLatitude: restaurant.Latitude! + mapsStore.tilt / 100 + 0.00015,
              maxLongitude: restaurant.Longitude! + mapsStore.tilt / 100 + 0.00015,
              minLatitude: restaurant.Latitude! + mapsStore.tilt / 100 - 0.00015,
              minLongitude: restaurant.Longitude! + mapsStore.tilt / 100 - 0.00015
            })
            selectedRestaurant.value = restaurant
          }
        },
        markerElement
      )

      placemarks.push(marker)
    }
  }
  await mapsStore.makeYMap(uid, 'halls', bounds)

  mapsStore.addYMapsFeatures(uid, placemarks)

  loaded.value = true
  lock = false
}

function clear(): void {
  selectedRestaurant.value = null
  mapsStore.changeYMapBounds(uid, bounds)
}

const uid = useId()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-restaurant-select-map-container {
  height: 450px;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}

.v-restaurant-icon {
  color: variables.$PrimaryBackgroundColor;
  cursor: pointer;
  width: 40px;
  height: 40px;
  fill: none;
}

.v-map-popup {
  top: 40px;
  left: 40px;
  padding: 20px;
  width: 400px;
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;

  max-height: 370px;
  overflow-y: auto;

  z-index: 1001;

  @include mixins.md {
    left: 30px;
  }

  @include mixins.sm {
    left: 0;
    width: 100%;
  }

  &--content {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &--left-part {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  &--close {
    flex: 0 0 30px;
    max-width: 30px;
  }

  .v-restaurant-popup {
    &--hr {
      display: flex;
      flex-direction: column;
      justify-content: center;

      hr {
        width: 100%;
        color: variables.$BodyTextColor;
        opacity: 0.25;
      }
    }
  }
}
</style>
